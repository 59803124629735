@import "../fontSizes";
@import "../utilities";

#launching {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		font-weight: bold;
		@include app-font-h2();
	}
}

@include responsiveMediaV2() {
	#launching {
		padding-top: 0;
	}
}
