@import "../common/colors.scss";
@import "utilities.scss";

html.uiv3 {
	.tooltip-trigger {
		position: relative;
		display: inline;
	}

	.tooltip {
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
		white-space: nowrap;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 105%;
		color: $app-black;
		padding: 0.4rem 0.8rem 0.4rem 0.8rem;
		background-color: $v3-body-background-color;
		border: 0.1rem solid $v3-tooltip-border-color;
		box-shadow: 0 0.2rem 0.4rem 0 $v3-primary-shadow-color-10;
	}
}

@include responsiveMediaV3(medium) {
	html.uiv3 .tooltip-trigger .tooltip {
		top: -200%;
		left: 50%;
		transform: translateX(-50%);
	}
}

@include responsiveMediaV3(small) {
	html.uiv3 .tooltip-trigger .tooltip {
		top: -4rem;
		left: 50%;
		transform: translateX(-50%);
	}
}
