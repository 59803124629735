@import "../common/utilities.scss";

.joinView {
	.header {
		display: flex;
		align-self: flex-start;

		.logo {
			height: 1.2rem;
		}
	}

	h1.title {
		align-self: stretch;
	}

	p.noSupport {
		margin-bottom: 0;
	}

	.partyImage {
		width: 25rem;
	}

	@include responsiveMedia(medium) {
		.partyImage {
			width: 20rem;
		}
	}

	@include responsiveMedia(small) {
		.partyImage {
			width: 15rem;
		}
	}

	.buttonsContainer {
		display: flex;

		.button {
			margin: 0.5rem;
			position: relative;
		}

		.button:hover .tooltip {
			display: block;
		}
	}

	.tooltip {
		position: absolute;
		background: #fff;
		display: none;
		border-radius: 0.2rem;
		bottom: 2.8rem;
		padding: 0.6rem 1.4rem;
		max-width: 100%;
		overflow: visible;
		width: 100%;
		white-space: normal;
		font-weight: 600;
		font-size: 0.8rem;
		left: -1.4rem;
		box-shadow: 0 0.2rem 0.4rem -0.075rem rgba(0, 0, 0, 0.25);
		text-align: left;
	}

	.tooltip:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		border-left: 0.7rem solid transparent;
		border-right: 0.7rem solid transparent;
		border-top: 0.7rem solid #fff;
		left: calc(50% - 1.2rem);
		bottom: -0.7rem;
	}

	@include responsiveMedia(medium) {
		.buttonsContainer {
			flex-direction: column;
		}
	}

	.desktopMessage {
		text-align: center;
		font-size: 0.9rem;
	}

	.launchLink {
		display: inline-block;
		position: relative;

		&:hover .tooltip {
			display: block;
		}

		.tooltip {
			bottom: 2rem;
		}
	}

	.tryEdgeAgain {
		margin-top: 0;
		font-size: 14px;
		margin-bottom: 20px;
	}

	.havingProblems {
		margin-top: -10px;
	}
}
