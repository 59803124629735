@import "utilities.scss";
@import "fontSizes.scss";
@import "colors.scss";

.footer {
	font-size: 0.8rem;

	a {
		font-weight: normal;
	}
}
@include responsiveMedia(small) {
	footer {
		width: calc(100vw - #{$global-horizontal-small-padding});
	}
}

/* UI modern */
.disclaimer {
	margin-bottom: 6.4rem;
	text-align: center;
	color: $v2-light-font-color;
	display: flex;
	justify-content: center;
	align-items: center;
	@include app-font-small();

	.view {
		display: block;
	}

	a {
		font-weight: normal;
		outline: none;
		color: $v2-light-font-color;
		margin: 1rem;
	}
}

@media screen and (max-width: 1002px) {
	.disclaimer {
		margin-bottom: 6.4rem;
		margin-top: 3.2rem;
	}
}
@media screen and (max-height: 600px) and (min-width: 1002px) {
	.disclaimer {
		margin-bottom: $global-vertical-small-padding;
	}
}

/* UI v3*/
html.uiv3 {
	.disclaimer {
		justify-content: center;
		align-items: center;
		margin-bottom: 2.4rem;
		display: flex;
		flex-direction: row;
		padding: 0;
		gap: 1.6rem;

		a {
			color: $anchor-color;
			transition: color 0.4s ease-in;
			margin: 0;
		}

		.view {
			flex-direction: row;
			justify-content: center;
			align-items: center;
			display: flex;
			margin: 0;
			gap: 1.6rem;

			.disclaimerTextWithLogo {
				color: $v3-base-font-color;
				display: flex;
				gap: 0.4rem;
			}
		}
	}
}

@include responsiveMediaV3(small) {
	html.uiv3 {
		.disclaimer {
			.view {
				.hideText {
					display: none;
				}
			}
		}
	}
}

// Styling for Spartan (Edge)
@supports (-ms-ime-align: auto) {
	html.uiv3 {
		.disclaimer {
			display: inline;
			> div:not(:last-child) {
				margin-right: 1.6rem;
			}
			.view {
				> :not(:last-child) {
					margin-right: 1.6rem;
				}
				.disclaimerTextWithLogo > :not(:last-child) {
					margin-right: 0.4rem;
				}
			}
		}
	}
}
