@import "../common/colors.scss";
@import "../common/fontSizes.scss";
@import "../common/utilities.scss";

html.uiv3 {
	.modernSimplifiedView {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0;
		gap: 2.4rem;
		width: 42rem;

		.mainActionsContent {
			display: flex;
			flex-direction: column;
			border: 0.05rem solid $v3-primary-shadow-color-15;
			background: $secondary-color;
			border-radius: 1.2rem;
			align-self: stretch;
			align-items: stretch;
		}

		.headerContainer {
			justify-content: center;
			display: flex;
			border-radius: 1.2rem 1.2rem 0 0;

			height: 15rem;
			margin-bottom: 4.8rem;
			overflow: visible;

			background-repeat: no-repeat;

			background: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
			background-size: 300% 300%;
			animation: shimmer 1.2s infinite ease;

			background-position: center center;

			@keyframes shimmer {
				0% {
					background-position: right;
				}

				100% {
					background-position: left;
				}
			}
		}

		.secondaryActionsContent {
			align-items: center;
			text-align: center;
			display: flex;
			flex-direction: column;
		}

		.actionsContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 3.2rem;
			gap: 3.2rem;
			background: $secondary-color;
			align-self: stretch;
			border-radius: 1.2rem;
		}

		.buttonsContainer {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
			gap: 0.8rem;
			align-self: stretch;
		}

		.logoContainer {
			position: relative;
			width: 9.8rem;
			height: 9.8rem;
			top: 9.8rem;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $secondary-color;
			box-shadow: 0 0 0.2rem $v3-primary-shadow-color-12,
				0 0.2rem 0.4rem $v3-primary-shadow-color-14;
			border-radius: 0.8rem;

			.logo {
				position: relative;
				width: 5.145rem;
				height: 5.145rem;
				align-self: center;
			}
		}
	}

	.modernSimplifiedView_notBranded {
		.headerContainer {
			height: 15rem;
			margin-bottom: 4.8rem;
			overflow: visible;
		}

		.logoContainer {
			opacity: 1;
		}
	}
}

@include responsiveMediaV3(big) {
	html.uiv3 {
		font-size: calc(0.95em * 0.625);
	}
}

@include responsiveMediaV3(medium) {
	html.uiv3 {
		font-size: calc(0.93em * 0.625);
	}
}

@include responsiveMediaV3(small) {
	html.uiv3 {
		font-size: calc(0.91em * 0.625);
	}
}

// Styling for Spartan (Edge)
@supports (-ms-ime-align: auto) {
	html.uiv3 {
		.modernSimplifiedView {
			> div:not(:last-child) {
				margin-bottom: 2.4rem;
			}
			.actionsContainer > div:not(:last-child) {
				margin-bottom: 3.2rem;
			}
			.buttonsContainer > div:not(:last-child) {
				margin-bottom: 0.8rem;
			}
		}
	}
}
