@import "../colors.scss";
@import "../fontSizes.scss";
@import "../utilities.scss";

html.uiv3 {
	.btn {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;
		border-radius: 0.4rem;
		padding: 1.05rem 0 1.05rem 0;
		cursor: pointer;
		border-width: 0.1rem;

		.text {
			text-align: center;
		}

		h3 {
			font-weight: 600;
			@include app-font-h3-button();
			margin: 0;
		}

		&:focus-visible {
			&:before {
				content: "";
				position: absolute;
				top: -0.1rem;
				bottom: -0.1rem;
				left: 0rem;
				right: -0.1rem;
				border-radius: 0.4rem;
				border: 0.4rem solid $secondary-color;
			}

			&:after {
				content: "";
				position: absolute;
				top: -0.2rem;
				bottom: -0.2rem;
				left: -0.1rem;
				right: -0.2rem;
				border-radius: 0.4rem;
				border: 0.3rem solid $v2-primary-shadow-color;
			}
		}

		&.primary {
			position: relative;
			background-color: $v3-primary-button-color;
			box-shadow: $v3-primary-shadow-color-25 0 0.2rem 0.4rem -0.075rem;
			border-color: transparent;
			transition: background-color 0.5s ease-in;

			.btnIcon {
				display: flex;
			}

			.icon {
				margin-right: 0.5rem;
			}

			& h3 {
				color: $secondary-color;
				transition: color 0.5s ease-in;
			}

			&:hover {
				box-shadow: 0 0.06rem 0.18rem 0 $v2-primary-shadow-color-11,
					0 0.32rem 0.72rem 0 $v2-primary-shadow-color-13;
				background-color: $v3-primary-button-color-hover;
				transition: background-color 0.5s ease-in;

				&:active {
					background-color: $v3-primary-button-color-active;
					transition: background-color 0.5s ease-in;
				}
			}
		}

		&.secondary {
			color: $v3-base-font-color-darker;
			position: relative;
			box-shadow: $v3-primary-shadow-color-10 0px 0.2rem 0.4rem -0.075rem;
			background-color: transparent;
			border: 0.1rem solid $secondary-border-color;
			flex: 1 1 0;
			transition: color 0.5s ease-in;

			.btnIcon {
				display: flex;
			}

			.icon {
				margin-right: 0.5rem;
			}

			& h3 {
				color: $v3-secondary-button-color-font;
				transition: background-color 0.5s ease-in;
			}

			& a {
				color: $anchor-color;
				font-weight: normal;
				outline: none;
			}

			&:hover {
				border-color: $v3-secondary-button-border;
				background-color: $v3-secondary-button-color-hover;

				&:after {
					content: "";
					background-color: transparent;
				}

				&:active {
					background-color: $v3-secondary-button-color-active;
				}
			}
		}
	}

	.secondaryAction {
		background-color: transparent;
		color: $v3-base-font-color-darker;
		@include app-font-h3-p();

		p {
			margin: 0;
		}

		& + & {
			border-top: 0.1rem solid $secondary-border-color;
		}

		& .text {
			margin: 0;
		}

		& a {
			color: $anchor-color;
			font-weight: normal;
			outline: none;
			transition: color 0.5s ease-in;
		}

		& .img {
			opacity: 0;
			width: 2.4rem;
			height: 2.4rem;
		}
	}
}
