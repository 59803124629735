@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Light");
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Semilight");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Semibold");
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Bold");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Light Italic");
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Semilight Italic");
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Italic");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Semibold Italic");
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: "Segoe UI";
	src: local("Segoe UI Bold Italic");
	font-style: italic;
	font-weight: 700;
}

body,
input,
select,
button {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Web", sans-serif;
}
