@import "../common/utilities.scss";
@import "../common/colors.scss";
@import "../common/fontSizes.scss";

$layout-width: 28.6rem;
$image-width: 20rem;

.modernMobileView {
	max-width: $layout-width;
	margin: 0 auto;

	color: $font-color-mobile;

	@include responsiveMedia(big) {
		.spacer {
			flex: 0;
		}
	}

	h1 {
		@include app-font-h1-mobile();
		color: $primary-color;
		font-weight: normal;
		width: $layout-width;
		margin: 0.6rem /*as 1rem is padding of parent box*/ 0 2.4rem;
	}

	.message {
		width: $layout-width;
		@include app-font-base-mobile();
		color: $font-color-mobile;
	}

	.illustration {
		width: $image-width;
		margin-bottom: 2.4rem;
	}

	// Larger mobiles have larger image
	@media screen and (min-device-width: 400px) {
		.illustration {
			width: 28rem;
		}
	}

	.legalNotice {
		font-size: 0.8rem;
		opacity: 0.8;
		width: $layout-width;
	}

	.checkboxContainer {
		margin-top: 1.6rem;
		font-size: 1.2rem;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: start;
	}

	.checkbox {
		margin-right: 0.8rem;
		accent-color: $primary-color;
	}
}
