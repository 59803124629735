@import "colors";
@import "fontSizes";

.ufd {
	width: 16rem;
	box-shadow: 0 1rem 2rem 0 rgba(22, 35, 58, 0.24);
	background-color: $primary-color;
	color: $secondary-color;
	border-radius: 0.2rem;
	padding: 0 1rem;
	position: absolute;
	text-align: left;

	&.bottom {
		bottom: 2rem;

		&::after {
			content: " ";
			position: absolute;
			left: 50%;
			border-top: 0.8rem solid $primary-color;
			border-right: 0.8rem solid transparent;
			border-left: 0.8rem solid transparent;
			border-bottom: none;
		}
	}

	&.bottom.upper {
		bottom: 10rem;
	}

	&.left {
		left: 2rem;
	}

	&.top {
		top: 2rem;
	}

	&.center {
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	p.title {
		font-weight: bold;
	}

	.msg {
		font-size: smaller;
	}
}

.ltr .ufd {
	&.left {
		left: 2rem;
	}

	&.right {
		right: 2rem;
	}
}

.rtl .ufd {
	&.right {
		left: 2rem;
	}

	&.left {
		right: 2rem;
	}
}

/* UI modern */
html.uiv2 .ufd {
	width: 28rem;
	padding: 0 1.6rem;
	border-radius: 0.3rem;
	box-shadow: $v2-primary-shadow-color-10 0 0.8rem 1rem -0.2rem;

	.title {
		@include app-font-h3();
		margin: 1.6rem auto 0.8rem;
	}

	.msg {
		@include app-font-small();
		margin: 0.8rem auto 1.6rem;
	}

	&.bottom {
		&::after {
			border-top-width: 0.6rem;
			left: calc(50% - 0.8rem);
		}
	}

	&.top {
		&::after {
			border-bottom-width: 0.6rem;
		}
	}

	&.left {
		left: 3.2rem;
		&::after {
			border-top-width: 0.6rem;
			left: calc(10% - 1.2rem);
		}
	}
}

/* UI v3 */
html.uiv3 {
	.ufd {
		width: 32rem;
		height: 6.2rem;
		top: 3.2rem;
		display: flex;
		justify-content: left;
		align-items: center;
		background: $v3-ufd-background;
		box-shadow: 0 0 0.2rem $v3-primary-shadow-color-12, 0 0.2rem 0.4rem $v3-primary-shadow-color-14;
		border: 0.1rem solid $v3-primary-shadow-color-06;
		border-radius: 0.8rem;
		padding: 0;

		.ufdWrapper {
			display: flex;
			flex: 1;
			gap: 0.8rem;
			justify-content: left;
			align-items: center;
			margin: 0 1.2rem 0 1.2rem;

			.imgWrapper {
				display: flex;
				width: 2rem;
				height: 2rem;
			}

			.textWrapper {
				display: flex;
				flex-direction: column;
				flex: 1;
			}

			.title {
				line-height: 1.6rem;
				font-size: 1.2rem;
				color: $v3-base-font-color-darker;
				font-weight: 600;
				padding: 0;
				margin: 0;
			}

			.msg {
				line-height: 1.6rem;
				font-size: 1.4rem;
				color: $v3-base-font-color;
				padding: 0;
				margin: 0;
			}
		}

		&.bottom {
			&::after {
				border: 0;
			}
		}
	}
}

// Styling for Spartan (Edge)
@supports (-ms-ime-align: auto) {
	html.uiv3 .ufd .ufdWrapper > *:not(:last-child) {
		margin-right: 0.8rem;
	}
}
