/* fonts sized defined here works only with UI modern, where rem=10px */

/* 14 / 18 */
$line-height-base: 1.2857 !default;
/* 18px */
$app-font-size-base: 1.4rem;
/* 12 / 16 */
$app-font-size-small: 1.2rem;
$line-height-small: 1.6rem;
/* 32 / 40 */
$app-font-size-h1: 3.2rem;
$line-height-h1: 4rem;
/* 24 / 32 */
$app-font-size-h2: 2.4rem;
$line-height-h2: 3.2rem;
/* 18 / 20 */
$app-font-size-h3: 1.8rem;
$line-height-h3: 2rem;
/* 18 / 24 */
$app-font-size-h3-header: 1.8rem;
$line-height-h3-header: 2.4rem;
/* 14 / 19 */
$app-font-size-h3-button: 1.4rem;
$line-height-h3-button: 1.9rem;

/* mobile sizes */
/* 17 / 22 */
$app-font-size-base-mobile: 1.7rem;
$line-height-base-mobile: 2.2rem;

/* 28 / 38 */
$app-font-size-h1-mobile: 2.8rem;
$line-height-h1-mobile: 3.8rem;

@mixin app-font-base() {
	font-size: $app-font-size-base;
	line-height: $line-height-base;
}

@mixin app-font-h1() {
	font-size: $app-font-size-h1;
	line-height: $line-height-h1;
}

@mixin app-font-h2() {
	font-size: $app-font-size-h2;
	line-height: $line-height-h2;
}

@mixin app-font-h3() {
	font-size: $app-font-size-h3;
	line-height: $line-height-h3;
}

@mixin app-font-h3-button() {
	font-size: $app-font-size-h3-button;
	line-height: $line-height-h3-button;
}

@mixin app-font-h3-header() {
	font-size: $app-font-size-h3-header;
	line-height: $line-height-h3-header;
}

@mixin app-font-h3-p() {
	font-size: $app-font-size-base;
	line-height: $line-height-h3;
}

@mixin app-font-small() {
	font-size: $app-font-size-small;
	line-height: $line-height-small;
}

@mixin app-font-base-mobile() {
	font-size: $app-font-size-base-mobile;
	line-height: $line-height-base-mobile;
}

@mixin app-font-h1-mobile() {
	font-size: $app-font-size-h1-mobile;
	line-height: $line-height-h1-mobile;
}
