@import "../common/colors.scss";
@import "../common/fontSizes.scss";
@import "../common/utilities.scss";

$logo-size: 4.8rem;
$logo-position: 4.2rem;
html.uiv2 {
	.modernViewContainer {
		display: flex;
		flex: 1;
		flex-direction: column;
		min-height: 100vh;

		.logo {
			position: absolute;
			top: $logo-position;
			left: $logo-position;
			width: $logo-size;
			height: $logo-size;
		}

		#container {
			display: flex;
			flex: 1 1 auto;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}
}

@include responsiveMediaV2() {
	html.uiv2 {
		body {
			height: 100vh;
		}

		.modernViewContainer {
			display: flex;
			flex-direction: column;
			min-height: 100vh;

			.logo {
				position: initial;
				margin: $logo-position;
				top: auto;
				left: auto;
				display: flex;
				flex: 0;
			}
			#container {
				flex-direction: column;
				display: flex;
			}
		}
	}
}

html.uiv3 {
	.modernViewContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		flex: 1;
		justify-content: center;
		align-content: center;

		#container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0;
			gap: 2.4rem;
			flex: 1;
			justify-content: center;
		}
	}
}

@media screen and (max-width: 1600px) {
	html.uiv3 {
		.modernViewContainer {
			.disclaimer {
				margin-top: auto;
			}
		}
	}
}

// Styling for Spartan (Edge)
@supports (-ms-ime-align: auto) {
	html.uiv3 .modernViewContainer {
		min-height: 100vh;
		#container {
			flex: 1 0 auto;
			> div:not(:last-child) {
				margin-bottom: 2.4rem;
			}
		}
	}
}

html.uiv3 {
	.noShimmer {
		.modernSimplifiedView {
			.headerContainer {
				background: $secondary-color;
			}
			.logoContainer {
				top: 8rem;
			}
		}
	}

	.brandingColors {
		.modernSimplifiedView {
			.headerContainer {
				background-size: cover;
			}

			.logoContainer {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.btn {
			&.primary {
				background-color: var(--branding-primary-button-color);

				&:hover {
					background-color: var(--branding-primary-button-color-hover);

					&:active {
						background-color: var(--branding-primary-button-color-active);
					}
				}
			}
		}

		.secondaryAction {
			& a {
				color: var(--branding-anchor-color);
			}

			& .img {
				height: 2.4rem;
				animation: setOpacityToOne 0.8s ease forwards;
			}
		}

		.disclaimer {
			a {
				color: var(--branding-anchor-color);
			}
		}
	}

	.brandingAllImages {
		.modernSimplifiedView {
			.headerContainer {
				animation: setOpacityToOne 0.8s ease;
			}

			.logoContainer {
				animation: setTopPositionValueHigh 0.4s ease forwards;

				.logo {
					animation: setOpacityToZero 0.3s ease forwards;
				}
			}
		}
	}

	.brandingBackgroundOnly {
		.modernSimplifiedView {
			.headerContainer {
				animation: setMarginToZero 0.5s ease forwards, setOpacityToOne 0.5s ease;
			}

			.logoContainer {
				animation: setOpacityToZero 0.5s ease forwards;

				.logo {
					animation: setOpacityToZero 0.5s ease forwards;
				}
			}
		}
	}

	.brandingLogoOnly {
		.modernSimplifiedView {
			.headerContainer {
				animation: setBackgroundColorToWhite 0.2s ease forwards;
			}

			.logoContainer {
				animation: setOpacityToOne 0.8s ease, setTopPositionValueLow 0.4s ease forwards;

				.logo {
					animation: setOpacityToZero 0.3s ease forwards;
				}
			}
		}
	}

	.brandingNoImages {
		.modernSimplifiedView {
			.headerContainer {
				animation: setMarginToZero 0.6s ease forwards, setHeightToZero 0.6s ease forwards,
					setBackgroundColorToWhite 0.2s ease forwards;
			}

			.logoContainer {
				animation: setOpacityToZero 0.4s ease forwards;
			}
		}
	}

	.noBranding {
		.modernSimplifiedView {
			.headerContainer {
				animation: setBackgroundColorToWhite 0.2s ease forwards;
			}

			.logoContainer {
				animation: setTopPositionValueLow 0.4s ease forwards;
			}
		}
	}

	@keyframes setOpacityToOne {
		0% {
			opacity: 0.3;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes setOpacityToZero {
		100% {
			opacity: 0;
		}
	}

	@keyframes setMarginToZero {
		100% {
			margin-bottom: 0rem;
		}
	}

	@keyframes setHeightToZero {
		100% {
			height: 0;
		}
	}

	@keyframes setTopPositionValueLow {
		100% {
			top: 8rem;
		}
	}

	@keyframes setTopPositionValueHigh {
		100% {
			top: 9.8rem;
		}
	}

	@keyframes setBackgroundColorToWhite {
		0% {
			background: #eeeeee;
		}
		100% {
			// Ideally should be background: $secondary-color;
			// But transitioning the background from a linear-gradient does not work in Edge 15-18
			background-size: 0% 0%;
		}
	}
}

#rootViewV2.skypeSupported {
	background-color: white;
}

#rootViewV2.skypeUnsupported {
	background-color: #edeced;
}
