/* -force_new_hash- */

@import "components/common/fonts.scss";
@import "components/common/colors.scss";
@import "components/common/utilities.scss";

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	background: $background-color;
}

html.uiv2 {
	font-size: calc(1em * 0.625);
	-ms-high-contrast-adjust: none;
	-ms-overflow-style: scrollbar;

	&.edge {
		font-size: 62.5%;
		-ms-content-zooming: none;
		touch-action: none;
	}

	body {
		background-color: $v3-body-background-color;
		font-size: 1.4rem;
		line-height: 1.2857;
	}
}

html.uiv3 {
	font-size: calc(1em * 0.625);
	-ms-high-contrast-adjust: none;
	-ms-overflow-style: scrollbar;

	&.edge {
		font-size: 62.5%;
		-ms-content-zooming: none;
		touch-action: none;
	}

	body {
		background-color: $v2-background-color;
		font-size: 1.4rem;
		line-height: 1.2857;
	}
}

#rootView {
	min-height: calc(100vh - #{$global-vertical-padding});
	display: flex;
	color: $font-color;
	padding: $global-vertical-padding * 0.5 $global-horizontal-padding * 0.5;
}

#rootViewV2 {
	margin: 0;
	background-color: $v2-background-color;
	min-height: 100vh;
	display: flex;
}

#mobileRootViewV2 {
	min-height: calc(100vh - #{$global-vertical-padding});
	display: flex;
	color: $font-color;
	padding: $global-vertical-padding * 0.5 $global-horizontal-padding * 0.5;
}

a {
	color: $anchor-color;
	text-decoration: none;
	font-weight: bold;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

h1 {
	font-size: 1.5em;
}

@include responsiveMedia(medium) {
	#rootView {
		padding: $global-vertical-small-padding * 0.5 $global-horizontal-small-padding * 0.5;
	}

	#mobileRootViewV2 {
		padding: $global-vertical-small-padding * 0.5 $global-horizontal-small-padding * 0.5;
	}
}

@include responsiveMedia(small) {
	html:not(.uiv2) {
		font-size: 12px;
	}

	#rootView {
		min-height: calc(100vh - 2rem);
	}
}
