@import "colors.scss";
@import "fontSizes.scss";

.button {
	cursor: pointer;
	height: 2rem;
	min-width: 7rem;
	border: 0;
	position: relative;
	border-radius: 0.2rem;
	max-width: 20rem;
	padding: 0 1.25rem;

	box-shadow: rgba(0, 0, 0, 0.1) 0 0.2rem 0.4rem -0.075rem;
	background-color: $secondary-color;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 0.1rem solid transparent;
		border-radius: 0.2rem;
	}

	&:not(.primary)::after {
		border-color: $secondary-border-color;
	}

	&:not(.primary):hover {
		background: $secondary-color-hover;

		&::after {
			border-color: $secondary-border-color-hover;
		}
	}

	&:not(.primary):active,
	&:not(.primary):focus {
		background: $secondary-border-color;
		box-shadow: none;
		color: $app-black;

		&::after {
			border-color: $secondary-border-color-hover;
		}
	}

	&.primary {
		background-color: $primary-color;
		border-color: $primary-border-color;
		color: $secondary-color;
		box-shadow: 0 0.2rem 0.4rem -0.075rem rgba(0, 0, 0, 0.25);
	}

	&.primary:hover {
		background-color: $primary-color-hover;
	}

	&.primary:active,
	&.primary:focus {
		background: $primary-color-focus;
		box-shadow: none;
	}

	.buttonText {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
		font-weight: 600;
	}

	&.mobile {
		width: 100%;
		max-width: none;
		height: auto;
		padding: 1.7rem;
		margin-top: 1.2rem;
		margin-bottom: 2.4rem;
		border-radius: 0.4rem;
		box-shadow: none;
		display: flex;

		&::after {
			border-radius: 0.4rem;
		}

		& .buttonText {
			font-weight: normal;
			margin: 0 auto;
		}

		@include app-font-base();

		&:not(.primary) {
			color: $primary-color;

			&::after {
				border-color: $primary-color;
			}
		}
	}
}
