@import "../common/utilities.scss";
@import "../common/colors.scss";

.joinView {
	.header {
		display: flex;
		align-self: flex-start;

		.logo {
			height: 1.2rem;
		}
	}

	.bodyColumnCentered {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex: 1;
		flex-direction: column;

		.partyImage {
			width: 12rem;
		}

		.details {
			align-items: center;

			.googleTitle {
				font-size: 1.5rem;
				line-height: 2rem;
				font-weight: bold;
				margin-top: 2rem;
				font-family: "Segoe UI";
			}

			.description {
				font-size: 1.125rem;
				line-height: 1.5rem;
				max-width: 33.5rem;
				margin-bottom: 1rem;
			}
		}
		.buttonText {
			display: flex;
		}
	}
}
