@mixin responsiveMedia($size) {
	@if $size == big {
		@media screen and (min-width: 768px) and (min-height: 1024px) {
			@content;
		}
	} @else if $size == medium {
		@media only screen and (max-width: 767px) {
			@content;
		}
	} @else if $size == small {
		@media screen and (max-width: 350px) {
			@content;
		}
	}
}

$global-vertical-padding: 4rem;
$global-horizontal-padding: 6rem;
$global-vertical-small-padding: 2rem;
$global-horizontal-small-padding: 2rem;

/* ---- V2 ----- */
$default-spacing: 3.2rem;
$content-width: 42rem;
$padding-spacing: calc((#{$content-width} - 35.6rem) / 2);

@mixin responsiveMediaV2() {
	@media screen and (max-width: 1002px) {
		@content;
	}
}

@mixin responsiveMediaV3($size) {
	@if $size == big {
		@media screen and (min-width: 1001px) and (max-width: 1600px) {
			@content;
		}
	} @else if $size == medium {
		@media screen and (min-width: 601px) and (max-width: 1000px) {
			@content;
		}
	} @else if $size == small {
		@media screen and (max-width: 600px) {
			@content;
		}
	}
}
