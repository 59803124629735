.view {
	display: flex;
	flex: 1;
	flex-direction: column;

	.column {
		display: flex;
		flex-direction: column;
	}

	.body {
		flex: 1;
		text-align: center;
	}

	.centered {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.fillSpace {
		flex: auto;
	}
}
