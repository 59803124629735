@import "colors";
@import "fontSizes";
@import "utilities";
#textbox {
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;

	color: $v2-lighter-font-color;
	@include app-font-base();

	h1 {
		color: $font-color;
		font-weight: bold;
		@include app-font-h1();
		margin: 0;
	}

	p {
		color: $font-color;
		@include app-font-h2();
		margin: 0;
	}
}

@include responsiveMediaV2() {
	#textbox p {
		margin-bottom: 0;
	}
}

html.uiv3 {
	& #textbox {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $v2-lighter-font-color;
		@include app-font-base();
		gap: 0.6rem;

		h3 {
			color: $font-color;
			font-weight: bold;
			@include app-font-h3-header();
			margin: 0;
		}

		p {
			color: $v3-base-font-color;
			@include app-font-h3-p();
			margin: 0;
		}
	}

	@include responsiveMediaV2() {
		#textbox p {
			margin-bottom: 0;
		}
	}
}

// Styling for Spartan (Edge)
@supports (-ms-ime-align: auto) {
	html.uiv3 {
		& #textbox {
			> *:not(:last-child) {
				margin-bottom: 0.6rem;
			}
		}
	}
}
