@import "../common/utilities.scss";
@import "../common/colors.scss";

$layout-width: 18rem;

.joinMobileView {
	color: $font-color-mobile;

	@include responsiveMedia(big) {
		.spacer {
			flex: 0;
		}
	}

	.logo {
		width: $layout-width;
	}

	.message {
		width: $layout-width;
		margin: 1rem auto 1rem auto;
		font-size: 1.2rem;

		&.bold {
			font-size: 1.4rem;
			font-weight: bold;
		}
	}

	.illustration {
		margin: 1rem;
		width: $layout-width;
	}

	.legalNotice {
		font-size: 0.8rem;
		opacity: 0.8;
		width: $layout-width;
	}
}
